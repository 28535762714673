import PropTypes from 'prop-types';
import React from 'react';
import {SEO} from 'gatsby-theme-apollo-core';

const seoTitle = 'Givecloud Developer Documentation';
const seoDescription = "A developer's guide to maximizing impact and eliminating friction.";
const seoImage = 'https://developers.givecloud.com/docs-share-image.png';

export default function CustomSEO({ image, baseUrl, twitterHandle, ...props }) {

  return (
    <SEO {...props} twitterCard="summary_large_image">
      <title>{props.title + ' - ' + seoTitle}</title>
      <link rel="icon" href="/favicon.ico" />
      <meta name="og:title" content={seoTitle} />
      <meta name="twitter:title" content={seoTitle} />
      <meta name="og:description" content={seoDescription} />
      <meta name="twitter:description" content={seoDescription} />
      <meta property="og:image" content={seoImage} />
      <meta name="twitter:image" content={seoImage} />
      {twitterHandle && (
        <meta name="twitter:site" content={`@${twitterHandle}`} />
      )}
    </SEO>
  );
}

CustomSEO.propTypes = {
  baseUrl: PropTypes.string,
  image: PropTypes.string.isRequired,
  twitterHandle: PropTypes.string
};
